import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Box,
  Paper,
  Grid,
  Button,
  FormControl,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Divider,
  IconButton,
  InputAdornment,
  Tooltip,
  Autocomplete,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import typeOptions from 'constants/sp-account-type-options';
import successCheckMark from 'assets/success-check-mark.svg';

import AuthService from 'services/authService';
import signUpStore from 'store/signUpFormStore';
import settingsStore from 'store/settingsStore';
import withAuthHOC from 'components/common/authHOC/authHOC';
import formClasses from 'components/common/form/styles';

import Loading from 'components/common/loading';
import ComingSoon from 'components/common/comingSoon';
import ImageInput from 'components/common/form/imageInput';
import TagInput from 'components/common/tagInput';
import PasswordValidationSignUp from 'components/common/passwordValidationSignUp';

import { Helmet } from 'react-helmet-async'

import M from 'messages';
import classes from './style';

const SignUp = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [successSend, setSuccessSend] = useState(false);
  const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);
  const [recaptchaV2Response, setRecaptchaV2Response] = useState('');

  const dataErrors = signUpStore.getFormDataErrors();
  const data = signUpStore.getFormData();
  const showPassword = signUpStore.getShowPasswordState();
  const showRepeatPassword = signUpStore.getShowPasswordState(true);
  const maxSymbols = 255;

  const captchaKeys = signUpStore.getCaptchaKeys();
  const passwordErrors = signUpStore.getPasswordErrors();
  const passwordHasError = signUpStore.passwordHasError();

  useEffect(() => {
    signUpStore.clearCountriesData();
    signUpStore.getCountriesData();

    signUpStore.loadCaptchaKeys();
    settingsStore.getComingSoon();
    signUpStore.getCSRFToken();
    signUpStore.getSingleUseToken();

    return () => {
      signUpStore.reset();
    }
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formHasError = signUpStore.formHasError() || passwordHasError;
    if (!formHasError) {
      try {
        const formData = new FormData();
        // Obtain the reCAPTCHA token
        if (showRecaptchaV2 && recaptchaV2Response) {
          formData.append('recaptchaV2Response', recaptchaV2Response);
        } else {
          const token = await window.grecaptcha.execute(captchaKeys.RECAPTCHA_PUBLIC_KEY, { action: 'signup' });
          formData.append('g-recaptcha-response', token);
        }

        Object.entries(data).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        });

        const signUpResponse = await AuthService.signUp(formData);
        if (signUpResponse.data.success) {
          setSuccessSend(true);
        } else if (signUpResponse.data.showRecaptchaV2) {
          setShowRecaptchaV2(true);
          signUpStore.getCSRFToken();
          signUpStore.getSingleUseToken();
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(err?.response?.data?.message || M.get('login.errors.common'), { variant: 'error' });
        signUpStore.getCSRFToken();
        signUpStore.getSingleUseToken();
      }
      return true;
    }
    setLoading(false);
    enqueueSnackbar(M.get('form.errors.notValidForm'), { variant: 'error' });
    return false;
  };

  const getEmailHelperText = () => {
    let helperText = '';
    if (dataErrors.contactPersonEmail && dataErrors.contactPersonEmail === 'required') {
      helperText = M.get('form.errors.required');
    } else if (dataErrors.contactPersonEmail && dataErrors.contactPersonEmail === 'invalidEmail') {
      helperText = M.get(`login.errors.invalidEmail`);
    }
    return helperText;
  };

  const getPhoneHelperText = () => {
    let helperText = '';
    if (dataErrors.contactPersonPhone && dataErrors.contactPersonPhone === 'required') {
      helperText = M.get('form.errors.required');
    } else if (dataErrors.contactPersonPhone && dataErrors.contactPersonPhone === 'invalidPhone') {
      helperText = M.get(`form.errors.invalidPhone`);
    }
    return helperText;
  }

  const getLogoImageHelperText = () => {
    let helperText = '';
    if (dataErrors.logo && dataErrors.logo === 'required') {
      helperText = M.get('form.errors.required');
    } else if (dataErrors.logo && dataErrors.logo === 'maximumImageSize') {
      helperText = M.get(`form.errors.maximumImageSize`);
    }
    return helperText;
  }

  const getUrlFieldHelperText = (name) => {
    let helperText = '';
    if (dataErrors[name] && dataErrors[name] === 'required') {
      helperText = M.get('form.errors.required');
    } else if (dataErrors[name] && dataErrors[name] === 'invalidUrl') {
      helperText = M.get(`form.errors.invalidUrl`);
    }
    return helperText;
  }

  const isSaveButtonDisabled = () => !signUpStore.getCreateFormChanged();

  if (successSend) {
    return (
      <Box
        sx={classes.successContainer}
        component={Paper}
        p={2}
        mt={12}
        width={375}
        margin="auto"
        elevation={3}
      >
        <img src={successCheckMark} alt="success" style={classes.emailImg} />
        <h1 > {M.get('signUp.successTitle')} </h1>
        <p style={classes.successDescription}>
          {M.get('signUp.successDesc')}
        </p>
      </Box>
    );
  }

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  }

  if (loading || (data.id && !signUpStore.isCountryOptionLoaded)) {
    return <Loading />;
  }

  if (loading || !signUpStore.isCountriesLoaded() || !settingsStore.isComingSoonLoaded() || !signUpStore.getCaptchaKeysLoaded()) {
    return <Loading customClass={classes.loading} />;
  }

  if (settingsStore.getShowComingSoon()) {
    return <ComingSoon />;
  }

  return (
    <Box sx={classes.container} component={Paper} margin="auto" elevation={3}>
      <Helmet>
        <script async defer src={`https://www.google.com/recaptcha/api.js?render=${captchaKeys.RECAPTCHA_PUBLIC_KEY}`} />
      </Helmet>
      <h3 style={classes.title}> {M.get('login.signUp')} </h3>
      <form onSubmit={onSubmit} noValidate>
        <Box component={Paper} pb={2} px={3} pt={3}>
          <Grid container spacing={3}>
            <Grid container item xs={12} sm={6} spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                  <TextField
                    label={`${M.get('signUp.name')}`}
                    id="name"
                    name="name"
                    value={data.name}
                    required
                    onChange={signUpStore.onChangeField}
                    variant="outlined"
                    error={!!dataErrors.name}
                    inputProps={{ maxLength: maxSymbols }}
                    helperText={
                      dataErrors.name ?
                        M.get('form.errors.required') :
                        <Box component='span' sx={classes.helperDiv}>
                          <Box component='span'>{data.name?.length || 0}/{maxSymbols}</Box>
                        </Box>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>               
                <FormControl fullWidth sx={classes.formControl}>
                  <InputLabel id="type-select-select-label">{`${M.get('signUp.partnerType')} *`}</InputLabel>
                  <Select
                    sx={{
                      ...(dataErrors.type &&
                        formClasses.invalidSelectContainer),
                    }}
                    value={data.type}
                    onChange={signUpStore.onChangeField}
                    id="type-select"
                    name="type"
                    label={`${M.get('signUp.partnerType')}`}
                    error={!!dataErrors.type}
                    required
                  >
                    {typeOptions.map((opt) => (
                      <MenuItem key={opt.id} value={opt.value}>
                        {opt.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {dataErrors.type && (
                    <FormHelperText sx={formClasses.errorHelperText}>
                      {' '}
                      {M.get('form.errors.required')}{' '}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                <TextField
                  label={`${M.get('signUp.description')}`}
                  id="description"
                  name="description"
                  value={data.description}
                  onChange={signUpStore.onChangeField}
                  multiline
                  rows="6"
                  size="medium"
                  required
                  error={!!dataErrors.description}
                  helperText={
                    dataErrors.description && M.get('form.errors.required')
                  }
                  InputProps={{
                    sx: { position: 'relative', paddingRight: '40px' },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          position: 'absolute',
                          top: '25px',
                          right: '12px',
                        }}
                      >
                        <Tooltip placement="top" title={M.get('serviceProvider.descriptionTooltip')}>
                          <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
               <FormControl fullWidth sx={classes.formControl}>
                <Autocomplete
                  id="countryId"
                  variant="outlined"
                  freeSolo
                  clearOnBlur
                  selectOnFocus
                  forcePopupIcon
                  handleHomeEndKeys
                  value={signUpStore.selectedCountry}
                  defaultValue={signUpStore.selectedCountry}
                  onChange={(e, value) => signUpStore.onChangeAutocomplete(value, 'countryId', 'countries')}
                  options={signUpStore.countriesOptions}
                  getOptionLabel={getOptionLabel}
                  getOptionDisabled={(option) => option.isDisabled}
                  renderOption={(props, option) => (<li key={option.id} {...props}>{option.name}</li>)}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      required
                      label={`${M.get('signUp.country')}`}
                      variant="outlined"
                      error={!!dataErrors.countryId}
                      helperText={(!!dataErrors.countryId) && M.get('form.errors.required')}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TagInput
                dataErrors={dataErrors}
                onChange={signUpStore.setTags}
                value={signUpStore.getTags()}
                options={[]}
                fieldName='partnerFeatureTags'
                placeholder={M.get('signUp.partnerFeatureTags')}
                tooltip={M.get('signUp.cuisinesTooltip')} 
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                <TextField
                  required
                  id="region"
                  name="region"
                  variant="outlined"
                  value={data.region}
                  onChange={signUpStore.onChangeField}
                  label={`${M.get('signUp.region')}`}
                  error={!!dataErrors.region}
                  inputProps={{ maxLength: maxSymbols }}
                  helperText={
                    dataErrors.region ?
                      M.get('form.errors.required') :
                      <Box component='span' sx={classes.helperDiv}>
                        <Box component='span'>{data.region?.length || 0}/{maxSymbols}</Box>
                      </Box>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                <TextField
                  label={M.get('signUp.website')}
                  id="website"
                  name="website"
                  value={data.website}
                  onChange={signUpStore.onChangeUrlField}
                  inputProps={{ maxLength: maxSymbols }}
                  error={!!dataErrors.website}
                  helperText={
                    dataErrors.website ? 
                      getUrlFieldHelperText('website') : 
                      <Box component='span' sx={classes.helperDiv}>
                        <Box component='span'>{data.website?.length || 0}/{maxSymbols}</Box>
                      </Box>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                <TextField
                  label={`${M.get('signUp.fullAddress')}`}
                  id="fullAddress"
                  name="fullAddress"
                  value={data.fullAddress}
                  onChange={signUpStore.onChangeField}
                  required
                  error={!!dataErrors.fullAddress}
                  
                  helperText={
                    dataErrors.fullAddress ?
                      M.get('form.errors.required') :
                      <Box component='span' sx={classes.helperDiv}>
                        <Box component='span'>{data.fullAddress?.length || 0}/{maxSymbols}</Box>
                      </Box>
                  }
                  InputProps={{
                    inputProps: {maxLength: maxSymbols},
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip placement="top" title={M.get('signUp.fullAddressTooltip')}>
                          <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                <TextField
                  label={M.get('signUp.reservation')}
                  id="reservation"
                  name="reservation"
                  value={data.reservation}
                  onChange={signUpStore.onChangeUrlField}
                  error={!!dataErrors.reservation}
                  helperText={
                    dataErrors.reservation ? 
                      getUrlFieldHelperText('reservation') : 
                      <Box component='span' sx={classes.helperDiv}>
                        <Box component='span'>{data.reservation?.length || 0}/{maxSymbols}</Box>
                      </Box>
                  }
                  InputProps={{
                    inputProps: { maxLength: maxSymbols },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip placement="bottom" title={M.get('serviceProvider.reservationTooltip')}>
                          <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box sx={classes.fieldHeader}>
                <Typography sx={classes.fieldHeaderText}>{`${M.get('signUp.logoFormat')} *`}</Typography>
              </Box>
              <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                <ImageInput
                  onChange={(e) =>
                    signUpStore.onChangeField({
                      target: { value: e, name: 'logo' },
                    })
                  }
                  defaultValue={data.logo}
                  errorStyle={!!dataErrors.logo}
                  isSignUp
                />
                {dataErrors.logo && (
                  <FormHelperText sx={formClasses.errorHelperText}>
                    {getLogoImageHelperText()}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={classes.divider} />
              <Typography color="textPrimary" sx={classes.contactPerson}>
                {M.get('signUp.contactPerson')}{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                <TextField
                  id="contactPersonName"
                  name="contactPersonName"
                  label={`${M.get('signUp.contactPersonName')}`}
                  value={data.contactPersonName}
                  required
                  onChange={signUpStore.onChangeField}
                  error={!!dataErrors.contactPersonName}
                  helperText={
                    dataErrors.contactPersonName ?
                      M.get('form.errors.required') :
                      <Box component='span' sx={classes.helperDiv}>
                        <Box component='span'>{data.contactPersonName?.length || 0}/{maxSymbols}</Box>
                      </Box>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                <TextField
                  label={`${M.get('signUp.contactPersonEmail')}`}
                  id="contactPersonEmail"
                  name="contactPersonEmail"
                  value={data.contactPersonEmail}
                  onChange={signUpStore.onChangeEmailField}
                  required
                  error={!!dataErrors.contactPersonEmail}
                  helperText={getEmailHelperText()}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                <MuiTelInput                         
                  id="contactPersonPhone"
                  name="contactPersonPhone"
                  value={data.contactPersonPhone}
                  defaultCountry='US'
                  error={!!dataErrors.contactPersonPhone}
                  onChange={signUpStore.onChangePhoneField} 
                  helperText={getPhoneHelperText()}
                  label={M.get('signUp.contactPersonPhone')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip placement="top" title={M.get('signUp.phoneTooltip')}>
                          <HelpOutlineIcon sx={classes.fieldHeaderIcon}/>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />

              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}/>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth sx={{...classes.margin, ...classes.formControl}}>
                <TextField
                  variant="outlined"
                  label={`${M.get('signUp.password')}`}
                  id="password"
                  name="password"
                  value={data.password}
                  required
                  onChange={signUpStore.onChangeField}
                  error={!!dataErrors.password || passwordHasError}
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{root: classes.eyeIcon}}
                          onClick={() => signUpStore.setEyeIconStateByField('showPassword')}
                          edge="end"
                          size="large">
                          {showPassword ? ( <VisibilityIcon /> ) : ( <VisibilityOffIcon /> )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
              <Grid item xs={12} ml={1}>
                <PasswordValidationSignUp passwordLength={8} passwordErrors={passwordErrors} hasError={passwordHasError}  />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth sx={{...classes.margin, ...classes.formControl}}>
                <TextField
                  variant="outlined"
                  label={`${M.get('signUp.repeatPassword')}`}
                  id="repeatPassword"
                  name="repeatPassword"
                  value={data.repeatPassword}
                  required
                  onChange={signUpStore.onChangeField}
                  error={!!dataErrors.repeatPassword}
                  helperText={dataErrors.repeatPassword && (dataErrors.repeatPassword === 'invalidPassword' ? M.get('form.errors.invalidRepeatPassword') : M.get('form.errors.required'))}
                  type={showRepeatPassword ? "text" : "password"}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{root: classes.eyeIcon}}
                          onClick={() => signUpStore.setEyeIconStateByField('showRepeatPassword')}
                          edge="end"
                          size="large">
                          {showRepeatPassword ? ( <VisibilityIcon /> ) : ( <VisibilityOffIcon /> )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='acceptedPrivacyPolicy'
                    checked={signUpStore.acceptedPrivacyPolicy}
                    onChange={signUpStore.onChangePrivacyCheckbox}
                  />
                }
                label={
                  <Typography variant="body2">
                    {M.get('signUp.agree')}{' '}
                    <a
                      href="https://sharevino.co/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {M.get('signUp.terms')}
                    </a>
                     {' '}{M.get('signUp.and')}{' '}
                    <a
                      href="https://sharevino.co/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {M.get('signUp.privacy')}
                    </a>
                    .
                  </Typography>
                }
              />
              {dataErrors.acceptedPrivacyPolicy && (
                <FormHelperText sx={formClasses.errorHelperText}>
                  {' '}
                  {M.get('form.errors.required')}{' '}
                </FormHelperText>
              )}
            </Grid>
            {showRecaptchaV2 && (
              <Grid item xs={12}>
                <Box sx={classes.fieldHeader}>
                  <Typography sx={classes.fieldHeaderText}>Please solve recaptcha above</Typography>
                </Box>
                <ReCAPTCHA
                  sitekey={captchaKeys.RECAPTCHA_PUBLIC_KEY_V2}
                  onChange={setRecaptchaV2Response}
                />
              </Grid>
            )}
          </Grid>
          <Grid item sx={classes.footerPart}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={classes.submit}
              disabled={isSaveButtonDisabled()}
              onClick={onSubmit}
            >
              {M.get('form.create')}
            </Button>
          </Grid>
        </Box>
         
      </form>
    </Box>
  );
});

export default withAuthHOC(SignUp, true);
