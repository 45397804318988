import React from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar, Typography, Icon } from '@mui/material';

import CustomSearch from 'components/common/customSearchV2';
import CustomFilter from 'components/common/customFilter';
import CustomToolbarAction from 'components/common/customToolbarAction';

import M from 'messages';
import classes from './styles';

const CustomTableToolbar = (props) => {
  const {
    onSearchCallback,
    filteredParams,
    numSelected,
    filterFields = [],
    onFilterCallback = null,
    handleDelete
  } = props;

  const searchValue = filteredParams?.params?.search?.value || '';

  return (
    <>
      <Toolbar sx={classes.root}>
        <Box sx={classes.filters}>
          <CustomSearch
            onSearchCallback={onSearchCallback}
            searchValue={searchValue}
          />
          <CustomFilter
            onFilterCallback={onFilterCallback}
            filterFields={filterFields}
            filteredParams={filteredParams}
          />
        </Box>
        <Box sx={classes.actions}>
          {numSelected > 0 &&
            <Typography sx={classes.headerText}>
              {`${numSelected} ${M.get('table.itemSelected')}`}
            </Typography>}
            {numSelected > 0 && handleDelete && <CustomToolbarAction
              handleOnClick={handleDelete}
              btnText={M.get('actions.delete')}
              tooltipText={M.get('actions.delete')}
              startIcon={<Icon>delete_forever</Icon>}
            />}
        </Box>
      </Toolbar>
    </>
  );
};

CustomTableToolbar.propTypes = {
  onSearchCallback: PropTypes.func.isRequired,
  filteredParams: PropTypes.instanceOf(Object).isRequired,
  numSelected: PropTypes.number.isRequired,
  onFilterCallback: PropTypes.func,
  filterFields: PropTypes.instanceOf(Array),
  handleDelete: PropTypes.func.isRequired,
};

export default CustomTableToolbar;