const en = {
  login: {
    username: 'Username',
    email: 'Email',
    password: 'Password',
    repeatPassword: 'Repeat password',
    signIn: 'Sign in',
    signUp: 'Sign up',
    signUpDesc: 'Don’t have an account? Sign up',
    errors: {
      common: 'Wrong credentials',
      invalidEmail: 'Must be a valid email'
    },
    blocked: {
      title: 'Too many failed log in attempts',
      description: 'Please contact ShareVino support for further steps or request a password reset link.',
      resetLink: 'Request reset link'
    }
  },
  signUp: {
    name: 'Partner Name',
    description: 'Description',
    partnerType: 'Partner Type',
    reservation: 'Reservation Webpage',
    partnerFeatureTags: 'Partner Feature Tags',
    cuisinesTooltip: 'Add feature tags to highlight key aspects of your offerings. These tags will be displayed on your partner card and wine list, helping customers quickly understand what makes your wines unique. Examples include "Organic," "Award-Winning," "Family-Owned," etc. Choose tags that best represent your strengths and specialties.',
    website: 'Webpage',
    country: 'Country',
    region: 'Region/City',
    fullAddress: 'Profile address',
    fullAddressTooltip: 'Please enter your address in the following recommended format: region/city, country. Alternatively, you may enter your address in a concise manner as you’d like it to appear on your profile. This information will be displayed on your partner card and wine list to help customers locate you easily.',
    logoFormat: 'Logo',
    contactPerson: 'Contact person',
    contactPersonName: 'Full Name',
    contactPersonEmail: 'Email',
    contactPersonPhone: 'Phone',
    phoneTooltip: "Please include your country code with a plus sign (+) when adding your phone number. For example, for a US number, enter +1 followed by your phone number.",
    password: 'Password',
    repeatPassword: 'Repeat password',
    successTitle: 'Thank you!',
    successDesc: 'Your submission has been sent.',
    agree: 'I agree with ShareVino’s',
    terms: 'ShareVino’s Terms & Conditions',
    privacy: 'Privacy Policy',
    and: 'and',
  },
  users: {
    pageTitle: 'Users',
    name: 'Name',
    phoneNumber: 'Phone number',
    email: 'Email',
    currentPassword: 'Current password',
    changePassword: 'Change password',
    newPassword: 'New password',
    repeatNewPassword: 'Repeat new password',
    oldPassword: 'Old password',
    active: 'Active',
    inactive: 'Inactive',
    approved: 'Approved',
    notApproved: 'Not approved',
  },
  kiosksMenu: {
    name: 'Menu name',
    menuTitle: 'Wine list name',
    pageTitle: 'Wine list',
    items: 'Wines',
    itemsPlaceholder: 'Select wines',
    serviceProvider: 'Partner',
    kioskMenus: 'Wine list',
    menuItemHelper: 'Enter wine list name',
    noOptions: 'No options',
    loading: 'Loading...',
    deleteConfirmationTitle: 'Are you sure you want to remove this wine list?',
    deleteConfirmationText: 'If deleted, it will not be possible to recover the wine list.',
    filter: {
      partner: 'Partner'
    },
    qr: {
      redirectUrl: 'Redirect URL',
      generate: 'Generate QR code',
      qrImage: 'QR image',
      qrImageTooltip: 'Right-click on the QR code and select "Save Image As" to download and save it to your device.'
    },
    shouldResetWineRating: 'Activate ClearRate',
    shouldResetWineRatingTooltip: 'Enable ClearRate feature to reset all current ShareVino ratings for this wine list to zero, allowing for fresh perspectives and feedback on wines tailored to your event or selection. This is a one-time action; once activated, the ratings will start anew but still contribute to the total rating. This feature is ideal for wine events, blind-tasting experiences, and wine competitions. Disable to revert to all-time general ratings.',
    useTableOrWineTooltip: ' Assign a table or wine number to the wines on this list to showcase your wines during wine events with a specific number. The number will apply to all wines included on the wine list. If you prefer to assign individual numbers to each wine, use the wine-specific table or wine number feature within wine profiles.',
    winesTooltip: 'To add wines to your list, first create them in the ‘Wines’ section. You can rearrange the wines by dragging them into your desired order.',
    createLimit: 'To create additional wine lists, please consider upgrading your plan.',
  },
  wineLabels: {
    pageTitle: 'Wine labels',
    pageDescription: 'Create and manage wine labels.',
    allItems: 'All items',
    create: 'New wine label',
    createLimit: 'To create additional wine labels, please consider upgrading your plan.',
    filter: {
      partner: 'Partner'
    },
  },
  serviceProvider: {
    name: 'Partner Name',
    type: {
      title: 'Partner Type',
      winery: 'Winery',
      restaurant: 'Restaurant',
      importer: 'Importer',
      eventOrganizer: 'Event Organizer',
      other: 'Other',
    },
    description: 'Description',
    reservation: 'Reservation Webpage',
    partnerFeatureTags: 'Partner Feature Tags',
    cuisinesTooltip: 'Add feature tags to highlight key aspects of your offerings. These tags will be displayed on your partner card and wine list, helping customers quickly understand what makes your wines unique. Examples include "Organic," "Award-Winning," "Family-Owned," etc. Choose tags that best represent your strengths and specialties.',
    website: 'Webpage',
    accountStatus: {
      title: 'Account status',
      active: 'Active',
      inactive: 'Inactive',
    },
    country: 'Country',
    region: 'Region/City',
    fullAddress: 'Profile address',
    fullAddressTooltip: 'Please enter your address in the following recommended format: region/city, country. Alternatively, you may enter your address in a concise manner as you’d like it to appear on your profile. This information will be displayed on your partner card and wine list to help customers locate you easily.',
    order: 'Order',
    isQrPartner: 'QR partner',
    logoFormat: 'Logo',
    adminSection: 'Administrative Section',
    contactPerson: 'Contact person',
    creationDate: 'Creation date',
    contactPersonName: 'Full Name',
    contactPersonEmail: 'Email',
    contactPersonPhone: 'Phone',
    phoneTooltip: "Please include your country code with a plus sign (+) when adding your phone number. For example, for a US number, enter +1 followed by your phone number.",

    pageTitle: 'Partners',
    pageTitleSp: 'Settings',
    approvePartner: 'Are you sure you want to approve the partner(s) ?',
    approveSuccess: 'Partner(s) has been approved.',
    rejectPartner: 'Are you sure you want to reject the partner(s) ?',
    rejectSuccess: 'Partner(s) has been rejected.',
    rejectError: 'Failed to reject partner(s).',
    descriptionTooltip: 'Provide a brief overview of your establishment. Focus on key details like your history, attractive offerings, specialties, and unique qualities.',
    reservationTooltip: 'Add a web link to your reservation page. This will direct customers to book a table, visit, or event directly with your establishment.'
  },
  menuItem: {
    productDetails: 'Wine details',
    sku: 'SKU',
    barcode: 'Barcode',
    name: 'Wine name',
    slug: 'Wine slug',
    serviceProvider: 'Partner',
    description: 'Description',
    price: 'Price',
    discountedPrice: 'Discounted price',
    ingredients: 'Ingredients',
    image: 'Image',
    media: 'Media',
    additionalInfo: 'Additional info',
    cuisine: 'Cuisine',
    cuisineDescription: 'Choose one or two so that your customers can easily find this item. Leave blank if not applicable.',
    value: 'Value',
    shelfLife: 'Shelf life',
    availableAtKioskAndViaDelivery: 'Available at kiosk and via delivery',
    deliveryExclusive: 'Delivery exclusive',
    availableOnlyAtKiosk: 'Available only at kiosk',
    isSuperVino: 'Supervino',
    year: 'Year',
    itemImage: {
      name: 'Item image',
      description: 'Upload your wine image',
      imageDefaultLabel: 'Upload your image',
      message: 'Drop a jpg or png file, max 10MB',
      dropImage: 'Drop your image here or',
      browse: 'browse',
      original: 'Original',
      square: 'Square 250x250',
      landScape: 'Landscape 480x280',
      tooltip: 'Upload a good-quality image of the wine bottle, cropped from edge to edge, without any background. Ensure the bottle is clearly visible and fills the frame.',
    },
    filter: {
      name: 'Name',
      partner: 'Partner',
      minPrice: 'Min price',
      maxPrice: 'Max price',
    },
    ean8: 'EAN 8',
    ean13: 'EAN 13',
    upcA: 'UPC-A',
    enableBarcode: 'Select service provider to enable barcode',
    language: 'Language',
    changeLanguage: 'Add or remove language',
    defaultLanguage: 'Default -',
    loading: 'Loading...',
    noOptions: 'No options',
    colorTitle: 'Wine color *',
    red: 'Red',
    white: 'White',
    orange: 'Orange',
    rose: 'Rose',
    sweetnessTitle: 'Wine sweetness *',
    brutNature: 'Brut Nature',
    extraBrut: 'Extra Brut',
    brut: 'Brut',
    extraDry: 'Extra Dry',
    dry: 'Dry',
    demiSec: 'Demi-Sec',
    doux: 'Doux',
    semiDry: 'Semi-Dry',
    semiSweet: 'Semi-Sweet',
    sweet: 'Sweet',
    styleTitle: 'Wine style *',
    still: 'Still',
    sparkling: 'Sparkling',
    fortified: 'Fortified',
    desert: 'Desert',
    rating: 'Wine Critics Rating & Awards',
    superVino: 'Supervino',
    food: 'Pairing',
    partnerName: 'Partner',
    producer: 'Producer',
    color: 'Color',
    case: 'Case',
    glass: 'Glass',
    firstGlass: '1st Glass',
    twoFiveGlass: '2-5 Glass',
    flight: 'Flight',
    halfBottle: 'Half Bottle',
    carafe: 'Carafe',
    bottle: 'Bottle',
    magnum1_5l: 'Magnum 1.5L',
    magnum3l: 'Magnum 3L',
    bottle3L: 'Bottle 3L',
    halfCase: 'Half Case',
    threeCases: '3 Cases',
    fiveCases: '5 Cases',
    tenCases: '10 Cases',
    twentyPlusCases: '20+ Cases',
    wineSpecs: 'Wine specs',
    wineSpecTitle: 'Title',
    wineSpecDescription: 'Description',
    wineSpecView: 'Specification',
    countries: 'Country',
    region: 'Region',
    subRegion: 'Sub-region',
    wineYear: 'Wine year/Vintage',
    ratingFieldName: 'Rating type',
    cuisines: 'Cuisines',
    wineType: 'Grape Variety',
    foodsMaxMessage: 'Add up to {maxFoodsCount} foods.',
    special: 'Special',
    whereToBuy: 'Where to buy',
    pairingTitle: 'Pairing',
    pairing: 'Pairing',
    grapeVarietys: 'Grape Varietys',
    useTableOrWineNumber: 'Use table or wine number',
    isTableOrWine: 'Table or wine number',
    tableOrWineNumber: 'Table or wine number',
    table: 'Table',
    wine: 'Wine',
    unit: 'Unit',
    useTableOrWineNumberTooltip: 'Assign a table or wine number to showcase this wine during events with a specific number. The number will apply across all wine lists. If you prefer to assign the same number to all wines on the list, use the wine list-specific table or wine number feature within the wine lists. ',
    whereToBuyTooltip: 'Add a web link directing users to the page where your wines can be purchased.',
    wineSpecsTooltip: `This section allows you to share the story of your wine through 'About the Wine,' 'Tasting Notes,' and 'Pairing Recommendation.' Add descriptive text and upload images to bring each part to life and engage your audience.`,
    superVinoTooltip: `This feature will place a 'Supervino' tag on the wine, distinguishing it from others and highlighting it as a special selection.`,
  },
  labels: {
    producer: 'Producer',
    fat: 'Fat (g/100ml)',
    saturates: 'Of which saturates (g/100ml)',
    negligibleFat: 'Contains negligible amounts of fat (≤ 0.5 g/100ml)',
    carbohydrates: 'Carbohydrates (g/100ml)',
    sugars: 'Of which sugars (g/100ml)',
    negligibleCarbohydrates: 'Contains negligible amounts of carbohydrates (≤ 0.5 g/100ml)',
    protein: 'Protein (g/100ml)',
    negligibleProtein: 'Contains negligible amounts of protein (≤ 0.5 g/100ml)',
    salt: 'Salt (g/100ml)',
    negligibleSalt: 'Contains negligible amounts of salt (≤ 0.5 g/100ml)',
    percentage: 'Percentage',
    alcoholVolume: 'Alcohol by volume (ABV)',
    wine: {
      name: 'Wine/Product Name',
      year: 'Wine year/Vintage',
      type: 'Grape Variety',
      image: 'Product image',
      style: 'Wine style',
    },
    product: {
      productInfoTitle: 'Product Information',
      type: 'Product type',
      productTypeSparkling: 'Sparkling Wine Type',
      productTypeOther: 'Other Wine Type',
      grapeVariety: 'Grape variety'
    },
    countries: 'Country',
    deAlcoholization: 'De-alcoholization',
    dateOfMinimumDurability: 'Date of minimum durability/Best before',
    protectedGeographicalIndication: 'Protected geographical indication',
    provenanceOption: 'Origin/Indication of Provenance',
    carbohydratesTitle: 'Carbohydrates Content',
    carbohydratesLabel: 'Contains negligible amounts of Carbohydrates (≤ 0.5 g/100ml)',
    fatTitle: 'Fat Content',
    fatLabel: 'Contains negligible amounts of Fat (≤ 0.5 g/100ml)',
    proteinTitle: 'Protein Content',
    proteinLabel: 'Contains negligible amounts of Protein (≤ 0.5 g/100ml)',
    saltTitle: 'Salt Content',
    saltLabel: 'Contains negligible amounts of Salt (≤ 0.0125 g/100ml)',
    sweetness: {
      brutNature: 'Brut Nature',
      extraBrut: 'Extra Brut',
      brut: 'Brut',
      extraDry: 'Extra Dry',
      drySec: 'Dry (Sec)',
      demiSec: 'Demi-Sec',
      doux: 'Doux',
      dry: 'Dry',
      offDry: 'Off Dry',
      mediumDry: 'Medium Dry',
      medium: 'Medium',
      mediumSweet: 'Medium Sweet',
      sweet: 'Sweet',
    },
    style: {
      still: 'Still',
      semiSparkling: 'Semi-Sparkling',
      sparkling: 'Sparkling',
      fortified: 'Fortified',
      desert: 'Desert',
    },
    gasesAndPackagingGases: 'Gases & Packaging Gases',
    noAccess: 'Your current plan does not include access to this feature. Please upgrade to gain full access.',
    energy: {
      title: 'Energy Calculation',
      subTitle: 'These values will be saved as the final energy content:',
      useCalculator: 'Use Calculator',
      energyKcalLabel: 'Energy (kcal)',
      energyKJLabel: 'Energy (kJ)',
      modalTitle: 'Energy Calculator',
      modalSubTitle: 'Choose Calculation Method:',
      automaticCalculator: 'Automatic Calculator',
      manualCalculator: 'Manual Calculator',
      organicAcidLabel: 'Organic acid (g/100ml)',
      residualSugarLabel: 'Residual sugar (g/100ml)',
      polyolsLabel: 'Polyols/Glycerin (g/100ml)',
      alcoholLabel: 'Alcohol (Ethanol) (g/100ml)',
      proteinLabel: 'Protein (g/100ml)',
      fatLabel: 'Fat (g/100ml)',
      salatrimsLabel: 'Salatrims (g/100ml)',
      fibreLabel: 'Fibre (g/100ml)',
      calculatedEnergy: 'Calculated Energy',
      useThisValues: 'Use These Values'
    },
    serviceProvider: 'Partner',
    loading: 'Loading...',
    tooltips: {
      producer: 'Article 46 of Regulation (EU) 2019/33: ‘Producer’ means a natural or legal person or a group of such persons by whom or on whose behalf the processing of the grapes or of the grape musts into wine or the processing of grape must or wine into sparkling wines, aerated sparkling wine, quality sparkling wine or quality aromatic sparkling wines is carried out.',
    },
    ingredients: {
      title: 'Ingredients',
      acidityps: 'Acidity/pH Regulators',
      preservatives: 'Preservatives & Antioxidants',
      stabilizings: 'Stabilizing Agents',
      sweetenings: 'Sweetening & Enrichment substances',
      gases: 'Gases & Packaging Gases',
      liquors: 'Liquors (Sparking Wines Only)',
      others: 'Other practices',
      allergens: 'Allergens',
      tooltips: {
        acidityps: 'Substances used to maintain or adjust the acidity levels in wine, ensuring the right balance of flavors and stability.',
        preservatives: 'Ingredients added to protect the wine from spoilage and oxidation, helping to preserve its quality and extend its shelf life.',
        stabilizings: 'Substances that help maintain the wine\'s clarity, consistency, and prevent unwanted reactions during storage.',
        gases: '',
        liquors: 'Tirage liqueur creates the bubbles in sparkling wine, while expedition liqueur adjusts sweetness and flavor. The regulation allows the terms ‘tirage liqueur’ and ‘expedition liqueur’ to be displayed in the list of ingredients alone, and no need to list their actual constituents.',
        others: '',
        sweetenings: 'Added to adjust the sweetness or enhance the body of the wine. They can include sugar, grape concentrate, or other approved additives that influence the final flavor profile of the wine.',
        allergens: 'Ingredients that may cause allergic reactions or intolerances in some individuals. Allergens must also be indicated on the package or the label attached thereto.',
      }
    }

  },
  form: {
    save: 'Save',
    saveChanges: 'Save changes',
    errors: {
      required: 'Required',
      email: 'Must be a valid email',
      notValidForm: 'The form is not valid. Please check for errors.',
      invalidPhone: 'Must be a valid phone number',
      invalidRepeatPassword: 'Passwords do not match',
      accountNotApproved: 'Account not approved yet.',
      maximumImageSize: 'The allowed maximum file size should be 5 MB',
      invalidUrl: 'Must a valid url with https://',
    },
    dropFileOrUpload: 'Drop a file to upload, or click to select it.',
    create: 'Create'
  },
  table: {
    itemSelected: 'item selected',
    noResult: 'No results found',
    user: {
      id: 'ID',
      name: 'Name',
      role: 'Role',
      email: 'Email',
      status: 'Status',
      serviceProvider: 'Partner',
      siteAccessStatus: 'Site access',
      invited: 'Invite sent',
      invitationExpired: 'Invitation expired',
      active: 'Active',
      lastActivity: 'Last activity',
      resend: 'Resend',
      user: 'User',
    },
    sp: {
      id: 'ID',
      sortingOrder: 'Order',
      partnerName: 'Name',
      country: 'Country',
      profileAddress: 'Profile address',
      email: 'Email',
      type: 'Type',
      isQrPartner: 'QR partner',
      status: 'Status',
      approved: 'Approved',
      order: 'Order',
      createdAt: 'CreatedAt'
    },
    kiosksMenu: {
      id: 'ID',
      partner: 'Partner',
      name: 'Name',
      itemsCount: 'Wines',
      resetWineRating: 'Activate ClearRate',
      createdDate: 'CreatedAt',
      isDefault: 'Default Wine List',
    },
    wineLabels: {
      id: 'ID',
      partner: 'Partner',
      producer: 'Producer',
      name: 'Wine Name',
      country: 'Country',
      year: 'Year',
      color: 'Color',
      energyKcal: 'E kcal',
      published: 'On Market',
      createdAt: 'CreatedAt',
    },
    menuMenuItems: {
      name: 'Name',
      producer: 'Producer',
      wineCountry: 'Country',
      year: 'Year',
      color: 'Color',
      style: 'Style',
      sweetness: 'Sweetness',
      isSuperVino: 'SuperV',
      tableNumber: 'T/W №',
      createdAt: 'CreatedAt'
    },
    wines: {
      name: 'Name'
    }
  },
  footer: {
    poweredBy: 'Powered by',
    copyright: '© Technology',
    version: 'Version',
  },
  actions: {
    cancel: 'Cancel',
    save: 'Save',
    create: 'Create',
    edit: 'Edit',
    delete: 'Delete',
    discard: 'Discard',
    confirm: 'Confirm',
    clear: 'Clear',
    apply: 'Apply',
    clearSelection: 'Clear selection',
    createItem: 'Create wine',
    none: 'None',
    removeImage: 'Remove image',
    approve: 'Approve',
    reject: 'Reject',
  },
  general: {
    yes: 'Yes',
    no: 'No',
    loading: 'Loading',
    loadingDescription: 'Loading the page...',
  },
  profile: {
    pageTitle: 'Account',
    changeEmail: {
      title: 'Change Email',
      newEmail: 'New Email',
      sendConfirmationCode: 'Send Confirmation Codes',
      confirmEmailChange: 'Confirm Email Change',
      confirmationSent: 'Confirmation codes have been sent to your old and new email addresses. Please enter them below.',
      oldEmailCode: 'Old Email Code',
      newEmailCode: 'New Email Code',
    }
  },
  dashboard: {
    title: 'Dashboard',
    description: 'Key trends and reports from your business.',
    noAccess: 'Your current plan does not include dashboard access. Please upgrade to gain full access.',
  },
  pages: {
    login: 'Login',
    users: 'Users',
    sp: 'Partners',
    kiosksMenus: 'Wine lists',
    catalog: 'Wines',
    catalogue: 'Wine',
    configuration: 'Configuration',
    profile: 'Account details',
    dashboard: 'Dashboard',
    notFound: 'Page not found',
    usersAndPermissions: 'Users',
    spSettings: 'Profile',
    forgotPasswordText: 'Forgot password?',
    settings: 'Settings',
    wineLabels: 'EU wine labels',
  },
  notFound: {
    goBack: 'Back',
    title: 'Not found',
    description: 'Either you typed a wrong URL, or you followed a bad link.',
  },
  emptyPage: {
    addOne: 'Do you want to add one?',
    noData: 'No data yet',
    users: 'No users yet',
    menuItems: 'No wines yet',
    menus: 'No wine list yet',
    usersEmpty: 'There are no users in this account. Start by inviting the account holder to join ShareVino.',
    menusEmpty: 'No available wine list. Start by creating a wine list.',
    menuItemEmpty: 'Your wine list is empty. Add items to the {link} first.',
    catalog: 'Wines',
    menuItemsEmpty: 'Create wines manually.',
    downloadCsv: ' Download example CSV file.',
    menuItemsArchiveEmpty: 'Wine archive is empty.',
    menuItemsTableEmpty: 'No item!  please select item in advance yet!',
    createSpForMenuItem: 'You need to create a partner to add wines. Create {link} one now.',
    serviceProviderText: 'Partner',
    labelsEmpty: 'No wine labels yet',
  },
  actionMsg: {
    success: {
      operationSucceeded: 'Operation successful.',
    },
    error: {
      unknownError: 'Unknown error.',
      imageMaxSize: 'Image max resolution should be {width} × {height}',
      imageMinSize: 'Image min resolution should be {width} × {height}',
      imageMinMaxSize: 'Image resolution should be min: {minWidth} × {minHeight} max: {maxWidth} × {maxHeight}',
    },
  },
  forgotPassword: {
    email: 'Email',
    submit: 'Submit',
    send: 'Send',
    cancel: 'Cancel',
    back: 'Back to sign in',
    title: 'Forgot Password',
    description: 'Enter your email and we’ll send you a link to reset your password',
    success: {
      title: 'Email sent',
      descriptionPart_1: 'Please follow the instructions in the email we sent to ',
      descriptionPart_2: 'in order to regain access to your account.'
    },
    errors: {
      invalidEmail: 'Must be a valid email'
    },
  },
  resetPassword: {
    title: 'Create a new password to access your account',
    expiredToken: 'Your token has expired, please submit your request again.',
  },
  changePassword: {
    title: 'Update your password for security reasons',
  },
  catalog: {
    pageTitle: 'Wines',
    createLimit: 'To create additional wines, please consider upgrading your plan.',
    deleteConfirmationTitle: 'Are you sure you want to permanently delete this item?',
    deleteConfirmationText: 'If deleted, it will not be possible to recover the item.',
  },
  filterFields: {
    search: 'Search',
    none: 'None',
    max: 'Max',
    min: 'Min',
    noMatches: 'No matches found',
    unlocked: 'Unlocked',
    locked: 'Locked',
    withOrder: 'With order',
    noOrder: 'No order',
    attached: 'Attached',
    notAttached: 'Not Attached',
    successful: 'Successful',
    pending: 'Pending',
    processing: 'Processing',
    failed: 'Failed',
    canceled: 'Canceled',
    all: 'All',
    withReview: 'With review',
    onlyRatings: 'Only ratings',
    hasAccess: 'Has access',
    noAccess: 'No access',
    draft: 'Draft',
    enabled: 'Enabled',
    disabled: 'Disabled',
    applied: 'Applied',
    notApplied: 'Not applied',
    yes: 'Yes',
    no: 'No',
    imperial: 'Imperial',
    metric: 'Metric',
    left: 'Left',
    right: 'Right',
    enable: 'Enable',
    disable: 'Disable'
  },
  passwordValidation: {
    password: 'Password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    repeatNewPassword: 'Repeat new password',
    save: 'Save',
    passwordsNotMatch: 'Passwords don’t match',
    minCharacter: 'Must be at least {passwordLength} characters long',
    upperAndLowerCaseCharacters: 'Mixed uppercase and lowercase characters',
    specialCharacter: 'At least 1 number and 1 special character'
  },
  usersRoles: {
    superAdmin: 'Global admin',
    partner: 'Partner'
  },

  account: {
    pageTitle: 'Users',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    phoneTooltip: "Please include your country code with a plus sign (+) when adding your phone number. For example, for a US number, enter +1 followed by your phone number.",
    password: 'Password',
    changePassword: 'Change password',
    role: 'Role',
    activityStatus: 'Activity status',
    planException: 'Partner Exception Without Subscription',
  },
  settings: {
    pageTitle: 'Settings',
    searchCountry: 'Search and add countries',
    countries: 'Countries',
    wineTypes: 'Wine types',
    searchWineType: 'Search and add wine types',
    partnerSort: 'Partner sort type',
    ourImpact: 'Our impact',
    wines: 'Wines',
    partners: 'Partners',
    winelovers: 'Winelovers',
    wineEvents: 'Wine events',
    name: 'Name',
    count: 'Count',
    subscriptionsTitle: 'Subscription Bullets',
    searchBullet: 'Search and add bullets',
    showComingSoon: 'Show coming soon',
    showEuPlan: 'Show EU plans',
    showDashboard: 'Show dashboard',
  },
  subscription: {
    title: 'Subscription plans',
    subscribe: 'Subscribe',
    currentPlan: 'Current plan',
    freeCurrencyDesc: 'free forever',
    everythingIn: 'Everything in {planName}:',
    wineCount: 'Up to {wineCount} wines.',
    unlimitedWine: 'Unlimited wines.',
    labelCount: 'Up to {labelCount} labels.',
    unlimitedLabel: 'Unlimited labels.',
    wineListCount: 'Up to {wineListCount} wine list.',
    unlimitedWineList: 'Unlimited wine lists.',
    dashboardAccess: 'Dashboard for data analytics',
    billedMonthly: 'Billed monthly',
    billedYearly: 'Billed yearly',
    billedDesc: '/ Month',
    billedYearDesc: '/ Year',
    paymentSuccess: 'Your payment succeeded',
    paymentCanceled: 'Your payment was canceled',
    returnPortal: 'Return portal',
    manageBilling: 'Manage billing',
    customerPortal: 'Customer Billing Portal',
    statuses: {
      canceled: `Subscription is active but will cancel at the end of the period. After end you will downgrade to free plan. To see more you can click 'Customer Billing Portal'.`,
      active: `Subscription is active. To see more you can click 'Customer Billing Portal'.`,
      error: `You have a problem with your subscription. Please click the 'Customer Billing Portal' to see the problem.`,
      noSubscription: 'You do not have an active subscription.'
    },
    types: {
      eu: 'EU Wine Label Plans',
      standard: 'Wine List Plans'
    },
    modal: {
      title: 'Subscription Update (Upgrade/Downgrade)',
      description: `After clicking 'Confirm', your subscription changes will take effect immediately. You will be charged or credited according to the pricing of the new plan. Please review your selection carefully before proceeding. All updates are subject to`
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { en };